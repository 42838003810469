@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  background: rgb(248 250 252);
}
html{
  background:white;
}